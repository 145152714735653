import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { IAuthService } from '@shared/types/auth-service';
import { ICardsService } from '@shared/types/card-service';
import { ICardsCooldownCheckVM } from '@shared/types/cards-cooldown-check-vm';
import { INotificationService } from '@shared/types/notification-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';

export class CardsViewModel {
  private cardService = appInject<ICardsService>(DI_TOKENS.cardService);
  private cardsCooldownCheckVM = appInject<ICardsCooldownCheckVM>(DI_TOKENS.cardsCooldownCheckVM);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  private _cards: Array<CreditCardListModel> = [];

  constructor() {}

  get cards() {
    return this._cards;
  }

  addCardToList = (card: CreditCardListModel) => {
    this._cards = [card, ...this._cards];
  };

  async downloadCards() {
    await this.cardsCooldownCheckVM.fetchCooldownInfo();
    this._cards = await this.cardService.getCardList();
  }

  activePaymentSystem() {
    return this.paymentService.name;
  }

  addNewCard = async (cardInfo: any, isTemporary: boolean) => {
    const { email } = await this.authService.getUserInfo(true);
    const newPaymentMethod = await this.paymentService.createPaymentMethod(
      Object.assign(cardInfo, { email, isTemporary }),
    );
    return await this.cardService.saveCard(newPaymentMethod);
  };

  deleteCard = async (id: string) => {
    await this.cardService.removeCard(id);
    this._cards = this._cards.filter((c) => c.asJson.id !== id);
  };

  showSuccessMessage(text: string) {
    return this.notificationsService.showSuccess(text);
  }

  showErrorMessage(text: string) {
    return this.notificationsService.showError(text);
  }
}
