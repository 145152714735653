import { Dispatch } from 'react';

import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { t } from '@lingui/macro';
import { IPurchaseAndSellForm } from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/purchase-and-sell-form.validator';
import { NavigateFunction } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { TransactionOperationType } from '@shared/enums/transaction-operation-type.enum';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { ErrorKeysEnum, HttpErrorResponse } from '@shared/models/error/http-error-response';
import { CreateOrderModel } from '@shared/models/orders/create-model';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { ProductModel, ProductType } from '@shared/models/products/product-model';
import { GetRateModel } from '@shared/models/rates/get-model';
import { TermsDocumentModel } from '@shared/models/users/terms-documents-model';
import { Currency } from '@shared/models/wallets/currency';
import { LimitsModel } from '@shared/models/wallets/limits-model';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IAuthService } from '@shared/types/auth-service';
import { ICardsService } from '@shared/types/card-service';
import { ICardsCooldownCheckVM } from '@shared/types/cards-cooldown-check-vm';
import { IConfigService } from '@shared/types/config-service';
import { INotificationService } from '@shared/types/notification-service';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesService } from '@shared/types/rates-service';
import { IRatesVM } from '@shared/types/rates-vm';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { ITransactionsNotificationService } from '@shared/types/transactions-notification-service';
import { IUsersService } from '@shared/types/users-service';
import { IWalletsService } from '@shared/types/wallets-service';
import { amountToBigNumber, formatAmount } from '@shared/utils/metals';
import { BigNumber } from 'bignumber.js';

import { Screen } from './screens';

export abstract class PurchaseAndSellViewModel {
  protected cardsCooldownCheckVM = appInject<ICardsCooldownCheckVM>(DI_TOKENS.cardsCooldownCheckVM);
  protected walletsService = appInject<IWalletsService>(DI_TOKENS.walletsService);
  protected cardService = appInject<ICardsService>(DI_TOKENS.cardService);
  protected ratesService = appInject<IRatesService>(DI_TOKENS.ratesService);
  protected productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  protected paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  protected authService = appInject<IAuthService>(DI_TOKENS.authService);
  protected usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  protected notificationService = appInject<INotificationService>(DI_TOKENS.notificationService);
  protected configService = appInject<IConfigService>(DI_TOKENS.configService);
  protected smartCoinTrackingService = appInject<ISmartCoinTrackingService>(
    DI_TOKENS.smartCoinTrackingService,
  );
  protected transactionsNotificaionService = appInject<ITransactionsNotificationService>(
    DI_TOKENS.transactionsNotificationService,
  );
  protected ordersSmartCoinService = appInject<IOrdersSmartCoinService>(
    DI_TOKENS.ordersSmartCoinService,
  );
  protected ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
  protected isBuy: boolean;
  protected minLimitError: string;
  protected maxLimitError: string;
  protected navigateFn: NavigateFunction;
  protected _activeScreen = {
    data: Screen.PURCHASE_AND_SELL_FORM as Screen,
  };
  protected _document = {
    data: null as TermsDocumentModel | null,
  };
  protected _requestRatesError = {
    data: false,
  };
  protected _isExpiredRate = {
    data: false,
  };
  protected _rate = {
    data: this.ratesVM.rate as GetRateModel,
  };

  protected _downloadingErrors = {
    limits: false,
  };
  protected _maintenanceMode = {
    isActive: false as boolean,
  };

  createdOrder: CreateOrderModel | null;
  purchaseFormData: IPurchaseAndSellForm;
  selectedPaymentMethod: WalletsListModel | CreditCardListModel;
  smartCoinName: string;
  activeCurrency: string;
  wallets: Array<WalletsListModel>;
  limits: LimitsModel;
  cards: Array<CreditCardListModel>;
  products: Array<ProductModel>;
  setIsOpenVerificationDialog: Dispatch<boolean>;
  formData: IPurchaseAndSellForm | null;
  updateRatesTimeout: NodeJS.Timeout;

  constructor({
    smartCoinName,
    setIsOpenVerificationDialog,
    navigateFn,
  }: {
    smartCoinName: string;
    setIsOpenVerificationDialog: Dispatch<boolean>;
    navigateFn: NavigateFunction;
  }) {
    this.smartCoinName = smartCoinName;
    this.activeCurrency = this.ratesVM.currency;
    this.setIsOpenVerificationDialog = setIsOpenVerificationDialog;
    this.navigateFn = navigateFn;
    appMakeObservable(this, {
      _rate: appObservable,
      _activeScreen: appObservable,
      _requestRatesError: appObservable,
      _isExpiredRate: appObservable,
      _downloadingErrors: appObservable,
      _document: appObservable,
      _maintenanceMode: appObservable,
    });
  }

  get isActiveMaintenanceMode() {
    return this._maintenanceMode.isActive;
  }

  get smartCoinNameTitle() {
    return this.smartCoinName;
  }

  get confirmScreenTitle() {
    return t`Confirm Order`;
  }

  get document() {
    return this._document.data;
  }

  get downloadingLimitsError() {
    return this._downloadingErrors.limits;
  }

  get rate() {
    return this._rate.data || null;
  }

  get activeScreen() {
    return this._activeScreen.data;
  }

  get requestRatesError() {
    return this._requestRatesError.data;
  }

  get isExpiredRate() {
    return this._isExpiredRate.data;
  }

  get screenName() {
    return '-';
  }

  get round(): any {
    return BigNumber.ROUND_FLOOR;
  }

  get rateText() {
    return `1 ${this.smartCoinName} (1 g of ${this.getMetalNameBySmartCoinName(
      this.smartCoinName,
    )}) = `;
  }

  get rateTextSecondPart() {
    return `${this.rate?.asJson?.currency || ''} ${
      this.rate?.asJson?.isEmergencyEnabled ? '0.00' : this.rate?.asJson?.price || ''
    }`;
  }

  get footNoteFromConfirmationScreen() {
    return `* 1 ${
      this.createdOrder?.asJson.smartCoin
    } = 1 ${t`gram of physical`} ${this.getMetalNameBySmartCoinName(this.smartCoinName)}`;
  }

  get confirmScreenRateTitle() {
    return `${t`Per`} 1 ${this.createdOrder?.asJson.smartCoin || ''}`;
  }

  get confirmScreenRateValue() {
    return `${minDigitsAfterDot(amountPipe(this.rate?.asJson.price || 0, 2))} ${
      this.createdOrder?.asJson.fiatCurrency
    }`;
  }

  get isDisableConfirmScreenSubmitButton() {
    return (
      this.isInsufficient(
        this.rate?.asJson.price as number,
        this.createdOrder?.asJson.quantity as number,
        this.createdOrder?.asJson.transactionFee as number,
      ) ||
      !this.rate ||
      this.downloadingLimitsError ||
      Boolean(this.limitError) ||
      !this.rate.asJson.isSpot ||
      this.rate.asJson.isEmergencyEnabled ||
      this.requestRatesError
    );
  }

  get transactionTypeLabel() {
    return '-';
  }

  get isPurchase() {
    return this.isBuy;
  }

  get isSellOut() {
    return !this.isBuy;
  }

  get isFiatToken() {
    return this.productsVM.getProductTypeByCurrency(this.smartCoinName) === ProductType.FIAT_COIN;
  }

  get smartCoinAmountError(): boolean {
    return false;
  }

  get fiatCurrencyAmountError(): boolean {
    return Boolean(this.maxLimitError) || Boolean(this.minLimitError);
  }

  get limitError() {
    return this.minLimitError || this.maxLimitError;
  }

  get minCardLimit() {
    return minDigitsAfterDot(amountPipe(this.limits?.minCardLimit || 0), 2);
  }

  get maxCardLimit() {
    return minDigitsAfterDot(amountPipe(this.limits?.maxCardLimit || 0), 2);
  }

  get minWalletLimit() {
    return minDigitsAfterDot(amountPipe(this.limits?.minWalletLimit || 0), 2);
  }

  get documentTitle() {
    return '';
  }

  get maxWalletLimit() {
    return minDigitsAfterDot(amountPipe(this.limits?.maxWalletLimit || 0), 2);
  }

  get smartCoinInputPrecision() {
    return 8;
  }

  get noteMessage() {
    return '';
  }

  get receiptCurrency() {
    return this.createdOrder?.asJson.fiatCurrency || '';
  }

  get isAvailabeCards() {
    return true;
  }

  get paymentMethodDescription() {
    return '';
  }

  setActiveCurrency(value: string) {
    this.activeCurrency = value;
  }

  setCreatedOrder(createdOrder: CreateOrderModel | null) {
    this.createdOrder = createdOrder;
  }

  isValidRates() {
    return Boolean(
      this.rate &&
        this.rate.asJson &&
        this.rate.asJson.isSpot &&
        !this.rate.asJson.isEmergencyEnabled &&
        !this.requestRatesError,
    );
  }

  hasMinLimitError() {
    return Boolean(this.minLimitError);
  }

  async downloadWallets() {
    this.wallets = await this.walletsService.getList(this.ratesVM.currency);
  }

  async downloadLimits() {
    this.limits = await this.walletsService.getLimits();
  }

  async downloadCards() {
    await this.cardsCooldownCheckVM.fetchCooldownInfo();
    this.cards = await this.cardService.getCardList();
  }

  async fetchMaintenanceModeStatus() {
    await this.usersService.fetchMaintenanceModeStatus();
    this._maintenanceMode.isActive = this.usersService.isActiveMaintenanceMode;
  }

  async downloadRates(currency: Currency | undefined) {
    if (!currency || !this.smartCoinName) return;
    this._rate.data = await this.ratesService.getRates(currency, this.smartCoinName, this.isBuy);
  }

  async createSmartCoinOrder(
    formData: IPurchaseAndSellForm,
    smartCoin: string,
    fiatCurrency: string,
  ): Promise<CreateOrderModel | void> {}

  protected selectPaymentMethod(formData: IPurchaseAndSellForm) {
    if (formData.paymentType === MultiMetalPaymentType.deposit) {
      this.selectedPaymentMethod = this.wallets.find(
        (wallet) => wallet.asJson.id === formData.paymentMethodId,
      ) as WalletsListModel;
    } else if (formData.paymentType === MultiMetalPaymentType.card) {
      this.selectedPaymentMethod = this.cards.find(
        (card) => card.asJson.id === formData.paymentMethodId,
      ) as CreditCardListModel;
    }
  }

  async confirmSmartCoinOrder(orderId: string): Promise<{ id: string } | void> {}

  startTracking(orderId: string) {
    this.smartCoinTrackingService.startTracking({
      orderId: orderId,
      amount: this.createdOrder?.asJson.quantity || 0,
      smartCoin: this.smartCoinName,
      isBuy: this.isBuy,
    });
  }

  getMetalNameBySmartCoinName(smartCoinName: string): string {
    return (
      this.productsVM.products.find((product) => product.asJson.name === smartCoinName)?.asJson
        .baseCurrency || 'Metal'
    );
  }

  getProductByCurrency(currencyName: string) {
    return this.productsVM.getProductByCurrency(currencyName);
  }

  getProductTypeByCurrency(currencyName: string) {
    return this.productsVM.getProductTypeByCurrency(currencyName);
  }

  calculateFiatCurrencyAmount(smartCoinAmount: string): string {
    if (!this.rate || !this.rate.asJson) return '0';
    const amountBigNumber = amountToBigNumber(smartCoinAmount);
    const rateBigNumber = new BigNumber(this.rate.asJson.price);
    const currentSum = amountBigNumber.multipliedBy(rateBigNumber);
    return formatAmount(currentSum.toFixed(2), 2);
  }

  calculateSmartCoinAmount(fiatCurrencyAmount: string): string {
    if (!this.rate || !this.rate.asJson) return '0';
    const amountBigNumber = amountToBigNumber(fiatCurrencyAmount);
    const rateBigNumber = new BigNumber(this.rate.asJson.price);
    const currentSum = amountBigNumber.dividedBy(rateBigNumber);
    return formatAmount(currentSum.toFixed(8), 8);
  }

  calculateCostOfPurchase(amount: number, count: number): BigNumber {
    if (!amount || !count) return amountToBigNumber('0.00');
    const amountBigNumber = amountToBigNumber(`${amount}`);
    const countBigNumber = amountToBigNumber(`${count}`);
    return amountBigNumber.multipliedBy(countBigNumber);
  }

  calculateTotal(amount: number, count: number, fee: number): BigNumber {
    const costOfPurchaseBigNumber = this.calculateCostOfPurchase(amount, count);
    const feeBigNumber = amountToBigNumber(`${fee}`);
    return this.isPurchase
      ? costOfPurchaseBigNumber.plus(feeBigNumber)
      : costOfPurchaseBigNumber.minus(feeBigNumber);
  }

  isInsufficient(amount: number, count: number, fee: number = 0) {
    if (this.selectedPaymentMethod instanceof WalletsListModel) {
      const totalAmount = this.calculateTotal(amount, count, fee);
      return totalAmount.isGreaterThan(this.selectedPaymentMethod.asJson.balance);
    } else {
      return false;
    }
  }

  getCurrentLimits(paymentType: MultiMetalPaymentType | undefined) {
    if (!paymentType || !this.limits?.asJson?.creditCards || !this.limits?.asJson?.fiatWallets) {
      return null;
    }
    return paymentType === MultiMetalPaymentType.card
      ? this.limits?.asJson?.creditCards
      : this.limits?.asJson?.fiatWallets;
  }

  checkLimits(paymentType: MultiMetalPaymentType, amount: number, displayedFiatCurrency: string) {
    const currentLimit = this.getCurrentLimits(paymentType);
    if (!currentLimit) return;
    this.minLimitError =
      parseFloat(BigNumber(amount).toFixed(2, BigNumber.ROUND_FLOOR)) < currentLimit.lowerLimit
        ? t`Min limit ${minDigitsAfterDot(
            amountPipe(currentLimit.lowerLimit),
            2,
          )} ${displayedFiatCurrency}`
        : '';
    this.maxLimitError =
      parseFloat(BigNumber(amount).toFixed(2, BigNumber.ROUND_CEIL)) > currentLimit.upperLimit
        ? t`Max limit ${minDigitsAfterDot(
            amountPipe(currentLimit.upperLimit),
            2,
          )} ${displayedFiatCurrency}`
        : '';
  }

  isValidOrderByBalance = (
    formData: IPurchaseAndSellForm,
    paymentMethod: WalletsListModel | CreditCardListModel | undefined,
  ) => {
    return Boolean(formData && paymentMethod);
  };

  handleAddNewCard = async (
    paymentInfo: any,
    save: boolean,
  ): Promise<CreditCardListModel | null> => {
    let savedCard = null;
    try {
      const { email } = await this.authService.getUserInfo(true);
      const newPaymentMethod = await this.paymentService.createPaymentMethod(
        Object.assign(paymentInfo, { email, isTemporary: !save }),
      );
      savedCard = await this.cardService.saveCard(newPaymentMethod);
      this.cards.push(savedCard);
    } catch (e: HttpErrorResponse | any) {
      const { response, message } = e;
      if (response) {
        this.notificationService.showError(response.findFirstErrorMessage() || message);
        throw response.findFirstErrorMessage() || message;
      } else {
        this.notificationService.showError(message || t`Your card was declined.`);
        throw message || t`Your card was declined.`;
      }
    }
    return savedCard;
  };

  refreshExpirationTime = () => {
    this.updateRatesTimeout && clearTimeout(this.updateRatesTimeout);
    this._isExpiredRate.data = false;
    this.updateRatesTimeout = setTimeout(() => {
      this._isExpiredRate.data = true;
    }, 1000 * 30);
  };

  handleSmartCoinOrderSuccess(order: CreateOrderModel | void) {
    if (order) {
      this.setCreatedOrder(order);
      this.formData = null;
    }
    this._activeScreen.data = Screen.CONFIRM_SCREEN;
  }

  handleSmartCoinOrderConfirmSuccess(params: { orderId: string }, result: { id: string } | void) {}

  handleSmartCoinOrderError(e: HttpErrorResponse | any) {
    if (e.hasError(ErrorKeysEnum.User_Blocked)) {
      this.notificationService.showError(e.findErrorMessageByKey(ErrorKeysEnum.User_Blocked));
    } else if (e.hasError(ErrorKeysEnum.Limited_Metal_Left)) {
      this.notificationService.showError(e.findErrorMessageByKey(ErrorKeysEnum.Limited_Metal_Left));
    } else if (e.hasError(ErrorKeysEnum.User_Not_Verified)) {
      this.openVerificationDialog();
      return;
    } else if (e.hasError(ErrorKeysEnum.Market_Out_Of_Work_Hours)) {
      if (this._activeScreen.data !== Screen.PURCHASE_AND_SELL_FORM) {
        this._activeScreen.data = Screen.PURCHASE_AND_SELL_FORM;
      }
      this.downloadRates(this.activeCurrency as Currency);
      return;
    }
    this.notificationService.showError(t`Something went wrong, please try again`);
  }

  async fetchDocument() {
    this._document.data = await this.usersService.getDocument(this.documentTitle);
  }

  async handleDownloadRatesSuccess() {
    if (
      this.rate &&
      (!this.rate.asJson.isSpot || this.rate.asJson.isEmergencyEnabled) &&
      this._activeScreen.data !== Screen.PURCHASE_AND_SELL_FORM
    ) {
      this._activeScreen.data = Screen.PURCHASE_AND_SELL_FORM;
    }
    this._requestRatesError.data = false;
    this.refreshExpirationTime();

    if (this.createdOrder) {
      this.checkLimits(
        this.createdOrder.asJson.isCard
          ? MultiMetalPaymentType.card
          : MultiMetalPaymentType.deposit,
        parseFloat(
          BigNumber(this.createdOrder.asJson.quantity)
            .multipliedBy(this.rate?.asJson.price)
            .toFixed(2, this.round),
        ),
        this.createdOrder.asJson.fiatCurrency,
      );
      if (this.createdOrder.asJson.isCard) {
        const fee = await this.walletsService.getTransactionFee(
          this.createdOrder.asJson.paymentMethodId,
          this.isPurchase ? TransactionOperationType.PURCHASE : TransactionOperationType.SELL,
          parseFloat(
            BigNumber(this.createdOrder.asJson.quantity)
              .multipliedBy(this.rate?.asJson.price)
              .toFixed(2, this.round),
          ),
        );
        this.createdOrder.setTransactionFee(
          parseFloat(BigNumber(fee).toFixed(2, BigNumber.ROUND_CEIL)),
        );
      }
    }
  }

  handleDownloadRatesError(e: HttpErrorResponse | any) {
    if (
      this.rate &&
      (!this.rate.asJson.isSpot || this.rate.asJson.isEmergencyEnabled) &&
      this._activeScreen.data !== Screen.PURCHASE_AND_SELL_FORM
    ) {
      this._activeScreen.data = Screen.PURCHASE_AND_SELL_FORM;
    }
    this._requestRatesError.data = true;
  }

  handleDownloadLimitsError(e: HttpErrorResponse | any) {
    this._downloadingErrors.limits = true;
    this.notificationService.showError(e.findFirstErrorMessage());
  }

  handleDownloadCardsError(e: HttpErrorResponse | any) {
    this.notificationService.showError(e.findFirstErrorMessage());
  }

  handleDownloadWalletsError(e: HttpErrorResponse | any) {
    this.notificationService.showError(e.findFirstErrorMessage());
  }

  handleDownloadLimitsSuccess() {
    this._downloadingErrors.limits = false;
  }

  resetOrder() {
    this.setCreatedOrder(null);
    this._activeScreen.data = Screen.PURCHASE_AND_SELL_FORM;
  }

  openVerificationDialog() {
    this.setIsOpenVerificationDialog(true);
  }

  activePaymentSystem() {
    return this.paymentService.name;
  }

  getActiveWallet() {
    return this.wallets.find((w) => w.asJson.currency === this.smartCoinName);
  }

  saveState() {
    localStorage.setItem(
      `purchase_in_progress:${this.authService.userId}`,
      JSON.stringify(this.formData),
    );
  }

  loadState(): IPurchaseAndSellForm {
    let data = {} as IPurchaseAndSellForm;
    try {
      data = JSON.parse(
        localStorage.getItem(`purchase_in_progress:${this.authService.userId}`) || '{}',
      );
    } catch (ex) {
      this.removeState();
    }
    return data;
  }

  removeState() {
    localStorage.removeItem(`purchase_in_progress:${this.authService.userId}`);
  }

  protected showTransactionMessage(
    amount: number,
    currency: string,
    status: string,
    type: TransactionMessageType,
    id: string = '',
  ) {
    this.transactionsNotificaionService.showNotification({
      amount,
      currency,
      status,
      type,
      id,
    });
  }

  showSuccess(text: string) {
    this.notificationService.showSuccess(text);
  }

  showError(text: string) {
    this.notificationService.showError(text);
  }
}
