import React, { FC, useEffect } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Modal } from '@mui/material';
import { DI_TOKENS } from '@shared/constants/di';
import { ICardsCooldownCheckVM } from '@shared/types/cards-cooldown-check-vm';
import classNames from 'classnames';

import { styles } from './delete-card-dialog.styles';

export interface DeleteCardDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 270,
  border: 'none',
  borderRadius: '14px',
  paddingTop: '20px',
  background: 'rgba(37, 37, 37, 0.82)',
  outline: 'none',
};

const DeleteCardDialogComponent: FC<DeleteCardDialogProps> = ({
  classes,
  onClose,
  isOpen,
  onDelete,
}) => {
  const cardsCooldownCheckVM = appInject<ICardsCooldownCheckVM>(DI_TOKENS.cardsCooldownCheckVM);

  useEffect(() => {
    if (isOpen) {
      cardsCooldownCheckVM.startTrackTime();
    } else {
      cardsCooldownCheckVM.stopTrackTime();
    }
  }, [isOpen]);

  const handleDelete = () => {
    onDelete && onDelete();
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const content = (
    <div className={classes.viewBlock}>
      <div className={classNames(classes.titleBlock, classes.menuItem)}>
        <div className={classes.title}>{t`Payment Card Deletion`}</div>
        {cardsCooldownCheckVM.remainingMilliseconds ? (
          <div
            className={classes.text}
          >{t`If you delete this card, you will be able to add the next one after`}</div>
        ) : (
          <></>
        )}
        {cardsCooldownCheckVM.remainingMilliseconds ? (
          <div className={classes.text}>{t`${cardsCooldownCheckVM.getRemainingDays().days}d ${
            cardsCooldownCheckVM.getRemainingDays().hours
          }h ${cardsCooldownCheckVM.getRemainingDays().minutes}m`}</div>
        ) : (
          <></>
        )}
        <div className={classes.text}>{t`Are You sure you want to delete this payment card?`}</div>
      </div>
      <div
        className={classNames(classes.deleteButton, classes.menuItem)}
        onClick={handleDelete}
      >{t`Delete`}</div>
      <div
        className={classNames(classes.cancelButton, classes.menuItem)}
        onClick={handleClose}
      >{t`Cancel`}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="delete-card-label"
        aria-describedby="delete-card"
      >
        <Box sx={style}>{content}</Box>
      </Modal>
    </div>
  );
};

export default appWithStyles(styles)(DeleteCardDialogComponent);
